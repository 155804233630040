import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

export default function NewsDetails({ currentItem, feedUrl }) {
  const [scriptUrl, setScriptUrl] = useState("");

  useEffect(() => {
    let url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-newspage.js";

    if (currentItem.creator.toLowerCase().includes("telegraph")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-telegraph.js";
    }
    if (currentItem.creator.toLowerCase().includes("liverpool echo")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-liverpoolEcho.js";
    }
    if (currentItem.creator.toLowerCase().includes("manchester evening")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-manEvening.js";
    }
    if (currentItem.link.includes("independent.co.uk")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-independent.js";
    }
    if (currentItem.link.includes("sportbild")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-sportbild.js";
    }
    if (currentItem.link.includes("rmcsport")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-rmcsport.js";
    }
    if (currentItem.link.includes("as")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-as.js";
    }
    if (currentItem.link.includes("gazzetta")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-gazzetta.js";
    }
    if (currentItem.link.includes("portadelaidefc")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-portAdelaide.js";
    }
    if (currentItem.link.includes("sportskeeda")) {
      url =
        "https://ai-widget.s3.amazonaws.com/fl-ai-widget-sportskeeda-fighting.js";
    }
    if (feedUrl === "https://rss.app/feeds/yas0dbY43g6XuQVD.xml?ns=true") {
      url =
        "https://ai-widget.s3.amazonaws.com/fl-ai-widget-telegraph-inline-player.js";
    }
    if (feedUrl === "https://rss.app/feeds/LWoFFr5osYBJxmLH.xml") {
      url =
        "https://ai-widget.s3.amazonaws.com/fl-ai-widget-telegraph-non-soccer.js";
    }
    if (currentItem.link.includes("sport1")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-sid-sport1.js";
    }
    if (currentItem.link.includes("sport1")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-sid-sport1.js";
    }
    if (currentItem.link.includes("ran.de")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-sid-ran.js";
    }
    if (currentItem.link.includes("focus.de")) {
      url = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-sid-focus.js";
    }
    setScriptUrl(`${url}?re=${currentItem.guid}`);

    console.log(currentItem);
  }, [currentItem, feedUrl]);

  return (
    <div className="full-height-scroll p-3 ">
      <Helmet>
        <title>{currentItem.title}</title>
      </Helmet>

      <h1 className="mt-5" id="fl-ai-widget-prompt">
        {currentItem.title}
      </h1>
      <p className="mb-3">{currentItem.creator}</p>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "500px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "500px",
            objectFit: "contain",
            background:
              "linear-gradient(0deg, rgba(207,207,207,1) 0%, rgba(187,199,200,1) 100%)",
            background: `url(${currentItem.enclosure.url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            filter: "blur(30px)",
          }}
        ></div>
        <img
          src={currentItem.enclosure.url}
          alt="thumbnail"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "500px",
            objectFit: "contain",
          }}
        ></img>
      </div>
      <p className="my-3">{currentItem.contentSnippet}</p>
      <div id="fl-ai-widget-placement"></div>
      <Helmet>
        <script src={scriptUrl} async></script>
      </Helmet>
    </div>
  );
}
