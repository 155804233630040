import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RSSParser from "rss-parser";
import axios from "axios";
import aesjs from "aes-js";
import NewsDetails from "../components/NewsDetails";

export default function News(props) {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(0);
  const [URL, setURL] = useState("");
  const { id } = useParams();

  const parser = new RSSParser({
    customFields: {
      item: ["media:content"],
    },
  });

  const fetchRssFeed = async () => {
    const feedUrl = await decryptUrl();
    setURL(feedUrl);

    try {
      const res = await axios.get(feedUrl);
      const result = await parser.parseString(res.data);

      setItems(result.items);
    } catch (error) {
      console.log(error);
    }
  };

  const decryptUrl = async () => {
    let key = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    let iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];

    let encryptedBytes = aesjs.utils.hex.toBytes(id);
    let aesOfb = new aesjs.ModeOfOperation.ofb(key, iv);
    let decryptedBytes = aesOfb.decrypt(encryptedBytes);
    // Convert our bytes back into text
    let decryptedLink = aesjs.utils.utf8.fromBytes(decryptedBytes);

    return decryptedLink;
  };

  useEffect(() => {
    fetchRssFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (items.length === 0)
    return (
      <div className="container-fluid text-center">
        <div className="mt-5">
          <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          &nbsp;&nbsp;
          <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          &nbsp;&nbsp;
          <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="row full-height">
        <div className="col-md-3">
          <div className="full-height-scroll p-3 pt-0">
            <div
              style={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                height: "100px",
                background: "#fafafa",
              }}
            >
              <img
                src="https://footylight.com/widget-logo-light.svg"
                alt="FConnect Widget"
                className="my-4"
                width="100%"
                height="60px"
              />
            </div>
            {items.map((e, i) => (
              <div
                className={`card my-3 p-2 w-100 shadow-sm ${
                  i === currentItem ? "border-danger" : ""
                }`}
                key={e.guid}
              >
                <div className="card-body">
                  <h5 className="card-title">{e.title}</h5>
                  <p className="card-text">
                    ⭕️ By <b>{e.creator}</b>
                  </p>
                  <button
                    onClick={() => setCurrentItem(i)}
                    className="btn btn-sm btn-dark"
                  >
                    Read Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-9">
          <NewsDetails currentItem={items[currentItem]} feedUrl={URL} />
        </div>
      </div>
    </div>
  );
}
