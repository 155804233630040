import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import aesjs from "aes-js";

function Home() {
  const navigate = useNavigate();
  const [rssLink, setRssLink] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();

    if (rssLink === "") return;

    let key_128 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    let iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];

    let textBytes = aesjs.utils.utf8.toBytes(rssLink);
    let aesOfb = new aesjs.ModeOfOperation.ofb(key_128, iv);
    let encryptedBytes = aesOfb.encrypt(textBytes);
    let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

    navigate(`/rss/${encryptedHex}`);
  };

  return (
    <div className="d-flex flex-row justify-content-around">
      <div className="card shadow p-3 m-5 rounded" style={{ width: "400px" }}>
        <div className="d-flex flex-row justify-content-center align-items-center rounded">
          <img
            className="my-3"
            height="80px"
            src="https://footylight.com/widget-logo-light.svg"
            alt="cap"
          />
        </div>

        <div className="card-body mt-5 text-center">
          <h5 className="mb-3">Paste your RSS link here</h5>
          <form onSubmit={submitHandler}>
            <input
              className="form-control"
              placeholder="RSS feed link......"
              onChange={(e) => {
                setRssLink(e.target.value);
              }}
            />
            <input type="submit" className="btn btn-dark mt-3" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Home;
